// Angular modules
import { Component, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

// External modules
import Swal from 'sweetalert2';

// Services
import { StoreService } from '@services/store.service';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { CustomerService } from '@customers/customers-mgmt/services/customer.service';
import { CityService } from '@configuration/cities/services/city.service';

// Models
import { Customer } from '@customers/customers-mgmt/models/customer.model';
import { QueryParameter } from '@models/api-request.model';

// Enums
import { getCustomerTypes } from '@customers/enums/customer.enum';

// Validators
import { UniqueCustomerNameValidator } from '@customers/customers-mgmt/validators/unique-customer-name-validator.service';

// Helpers
import { CustomerFormGroupHelper } from '@customers/customers-mgmt/helpers/customer-form-configurer.helper';

// Base class
import { BaseAddComponent } from '@pages/base/base-add.component';
import { CustomAutocompleteComponent } from '@autocomplete/custom-autocomplete/custom-autocomplete.component';
import { EventBusService } from '@services/event-bus.service';


@Component({
  selector: 'lockbin-customer-add',
  templateUrl: './customer-add.component.html',
  styles: [],
})
export class CustomerAddComponent extends BaseAddComponent<Customer> implements OnInit
{

  @ViewChild('customAutocompleteCity') customAutocompleteComponent!: CustomAutocompleteComponent;

  searcher$!: Observable<any>; //searcher used in Autocomplete component

  // -------------------------------------------------------------------------------
  // #region NOTE Defined reactive form on Angular style ----------------------------
  // -------------------------------------------------------------------------------

  protected override formGroup!: FormGroup<{
    name: FormControl<string>;
    code: FormControl<string>;
    fiscalName: FormControl<string>;
    fiscalId: FormControl<string>;
    address: FormControl<string>;
    postalCode: FormControl<string>;
    phone1: FormControl<string>;
    phone2: FormControl<string>;
    email: FormControl<string>;
    web: FormControl<string>;
    latitude: FormControl<number>;
    longitude: FormControl<number>;
    customerType: FormControl<number>;

    cityId: FormControl<number>;

    cityName: FormControl<string>;
  }>;

  // -------------------------------------------------------------------------------


  customerTypes: any;

  // -------------------------------------------------------------------------------
  // NOTE Constructor & Init -------------------------------------------------------
  // -------------------------------------------------------------------------------
  constructor(
    protected override location: Location,
    protected override titleService: Title,
    protected override storeService: StoreService,
    protected override router: Router,
    protected override logger: NGXLogger,
    protected override translateService: TranslateService,
    protected override activatedRoute: ActivatedRoute,
    protected override eventBusService: EventBusService,
    private customerService: CustomerService,
    private uniqueCustomerNameValidator: UniqueCustomerNameValidator,
    private cityService: CityService
  ) {
    super(
      location,
      titleService,
      storeService,
      router,
      logger,
      translateService,
      activatedRoute,
      eventBusService
    );
  }

  override ngOnInit(): void {

    this.storeService.setIsLoading(true);

    this.customerTypes = getCustomerTypes();

    // setup searchers for autocompletes
    this.setupSearchers();

    // set routes to list/edit after page operation (add or edit) is done
    this.setupRoutesPostOperation();

    // setup FormGroup with validators, default values, etc.
    this.initFormGroup();

    // get params. Only are received when operation is edit
    this.activatedRoute.params
      .subscribe(params => {

        const id = params['id'];

        this.setupPageForAddOrEdit(id); //title, subtitle, and others

        if (id) { // is editing

          this.customerService.findById(id)
            .subscribe((itemToUpdate: Customer) => {

              // set entity value for update. If entity not exist (this shouldn't happen), initialize the variable to a new object
              this.entity = (itemToUpdate) ? itemToUpdate : new Customer();

              // reset form to default values from the 'this.entity' value
              this.setDefaultValuesToFormGroup();
              this.setTransientValuesToFormGroup();
              this.setAsyncValidators();

              // set values to autocomplete inputs fields and formGroup properties asociated with autocomplete
              this.setValuesToAutocompletes();

              this.storeService.setIsLoading(false);

            });
        } else { // is adding

          // set entity value for add
          this.entity = new Customer();

          // reset form to default values from the 'this.entity' value
          this.setDefaultValuesToFormGroup();
          this.setAsyncValidators();

          this.storeService.setIsLoading(false);

        }
      })
  }

  /**
  * Setups searchers to be used in automplete fields
  */
  setupSearchers() {
    const queryParams: QueryParameter = {};

    this.searcher$ = this.cityService.find(queryParams);
  }

  /**
   * Setups page for add or edit based on id
   * @param id
   */
  private setupPageForAddOrEdit(id: any) {
    if (id) {
      this.operationTitle = 'Edición de Cliente';
      this.operationSubtitle = 'Modifique los datos del cliente';
    } else {
      this.operationTitle = 'Nuevo Cliente';
      this.operationSubtitle = 'Rellene los datos del cliente';
    }
  }

  /**
   * Init formGroup with a helper
   */
  private initFormGroup() {
    this.formGroup = new CustomerFormGroupHelper().getCustomerFormGroup();
  }

  private setAsyncValidators() {

    this.formGroup.controls.name.addAsyncValidators(this.uniqueCustomerNameValidator.validateUniqueCustomerNameValidator(this.entity.id))

  }


  /**
   * Set routes to list/edit after page operation (add or edit) is done
   */
  private setupRoutesPostOperation() {
    this.routeToEdit = '/clientes/gestion-clientes/edit/';
    this.routeToList = '/clientes/gestion-clientes/list/';
  }

  /**
   * Set values to autocomplete input field once the value has been received from service
   */
  private setValuesToAutocompletes() {

    if (this.entity.country?.id > 0) {
      this.customAutocompleteComponent.fieldValue = this.entity.country?.name;
    }


  }
  // #endregion
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // #region Custom validations ----------------------------------------------------
  // -------------------------------------------------------------------------------
  /**
   * Evals if a customer name exits.
   * Due to this page support add/edit operations, need to check entity id's with same 'name' for the update operation
   */
  public hasErrorDueToCustomerNameExists(): boolean {
    return (
      this.formGroup.controls.name.hasError('customerNameExists') &&
      !this.formGroup.controls.name.errors?.['entityIdsWithSameTerm']?.includes(
        this.entity.id
      )
    );
  }

  // #endregion
  // -------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------
  // #region NOTE Actions ----------------------------------------------------------
  // -------------------------------------------------------------------------------

  doSelectItemAutocomplete(selectedItem: any) {

    this.logger.info('Seleccionado el..... --->', selectedItem);

    if (selectedItem?.item?.id) {
      this.formGroup.controls['cityId'].setValue(selectedItem.item.id);
      this.formGroup.controls['cityName'].setValue(selectedItem.item.name);
    }
  }

  public async onClickSubmit(): Promise<void> {
    const titleText =
      this.entity.id > 0
        ? this.translateService.instant('ALERT_UPDATE_HEADER')
        : this.translateService.instant('ALERT_ADD_HEADER');

    Swal.fire({
      title: titleText,
      text: this.translateService.instant('ALERT_CONFIRM_TEXT'),
      showClass: {
        popup: 'animated fadeInDown faster',
      },
      showCancelButton: true,
      confirmButtonText: this.translateService.instant('BTN_CONTINUE'),
      cancelButtonText: this.translateService.instant('BTN_CANCEL'),
    }).then((result) => {
      if (result.isConfirmed) {
        this.doSave();
      }
    });
  }

  private doSave() {
    this.setFormDataValuesToEntity();

    const saveObs$ = this.entity.id === 0 ? this.customerService.add(this.entity) : this.customerService.update(this.entity);
    const findByIdObs$ = (id: number) => this.customerService.findById(id);


    this.save(saveObs$, findByIdObs$);

  }

  protected override setTransientValuesToFormGroup() {
    this.formGroup.controls['cityName'].setValue(this.entity.city?.name);
    this.formGroup.controls['cityId'].setValue(this.entity.city?.id);
  }
  // #endregion
  // -------------------------------------------------------------------------------


}
