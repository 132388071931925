// Angular modules
import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

// Enums
import { CustomerType } from '@customers/enums/customer.enum';


@Injectable()
export class CustomerFormGroupHelper {

  constructor(
  ) {

  }

  public getCustomerFormGroup(): FormGroup {

      return new FormGroup({
        name: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: true,
            asyncValidators: [],
            updateOn: 'blur',
          }
        ),
        code: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        fiscalName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        fiscalId: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        address: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        postalCode: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        phone1: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        phone2: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        email: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required, Validators.email, Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        web: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [
              Validators.required,
              Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'),
              Validators.maxLength(255)],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        latitude: new FormControl<number>(
          { value: 0, disabled: false },
          {
            validators: [],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        longitude: new FormControl<number>(
          { value: 0, disabled: false },
          {
            validators: [],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        customerType: new FormControl<number>(
          { value: 0, disabled: false },
          {
            validators: [],
            nonNullable: false,
            updateOn: 'blur',
          }
        ),
        cityId: new FormControl<number>(
          { value: 0, disabled: false },
          {
            validators: [Validators.required],
            nonNullable: true,
            updateOn: 'blur',
          }
        ),
        cityName: new FormControl<string>(
          { value: '', disabled: false },
          {
            validators: [Validators.required],
            nonNullable: true,
            updateOn: 'blur',
          }
        ),
    })
  }
}


